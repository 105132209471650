import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, CircularProgress, Icon, Modal } from '@mui/material';
import { AxiosResponse } from 'axios';
import { KeyedMutator } from 'swr';

import { Images } from '@/constants/Images';
import useSubscription from '@/hooks/account/useSubscription';
import useAccountContext from '@/hooks/context/useAccountContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useUserTracking from '@/hooks/useUserTracking';
import { TodoAccountDataModel } from '@/models/Todo';
import ToDoAPI from '@/network/ToDoAPI';
import { handleApiError } from '@/utility/api';

import { useToDoActionHandler } from '../../constants/ToDoActionHandler';
import SaveWithProBadge from '../utility/statuses/SaveWithProBadge';
import ToDoCategories from '../utility/statuses/ToDoCategories';

const ToDoModal = ({
  todo,
  resetTodo,
  refreshTodos,
}: {
  todo: TodoAccountDataModel;
  resetTodo: () => void;
  refreshTodos: KeyedMutator<AxiosResponse | null>;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isSubscribed } = useSubscription();
  const [markingAsDone, setMarkingAsDone] = useState<boolean>(false);
  const { dispatchSnackbar } = useSnackbarContext();
  const { accountId } = useAccountContext();
  const { handleToDo } = useToDoActionHandler(todo);
  const userTracking = useUserTracking();

  const openTodo = () => {
    handleToDo();
    userTracking?.userClickedThroughToDo?.(todo);
  };

  const markAsDone = async (todo: TodoAccountDataModel) => {
    if (!accountId) return;
    try {
      setMarkingAsDone(true);
      const data = {
        status: todo.status === 'complete' ? 'active' : 'complete',
      };
      await ToDoAPI.updateToDo({ accountId: accountId, todoId: todo.type, data });
      if (data.status === 'complete') {
        userTracking?.userCompletedToDo?.(todo);
      } else {
        userTracking?.userReactivatedToDo?.(todo);
      }
      await refreshTodos();
      handleModalClose();
      setMarkingAsDone(false);
    } catch (error: unknown) {
      handleApiError({ error });
    }
  };

  const handleModalClose = () => {
    resetTodo();
  };

  return (
    <>
      <Modal open={true} onClose={handleModalClose}>
        <>
          <div className="to-do-modal">
            <div className="d-flex jc-space-between mb16">
              <ToDoCategories category={todo.category} />

              <Button
                className="m0 btn-black-2"
                disabled={markingAsDone}
                onClick={() => {
                  markAsDone(todo);
                }}
              >
                {markingAsDone && <CircularProgress size={16} />}
                {!markingAsDone && (
                  <p className="small p0">
                    {todo?.status !== 'complete' ? t('COMMON.MARK-AS-DONE') : t('COMMON.MARK-AS-ACTIVE')}
                    <span>
                      <Icon className="fs-inherit pl4 pos-rel t4 material-symbols-outlined">check_circle</Icon>
                    </span>
                  </p>
                )}
              </Button>
            </div>
            <h3>{todo?.content?.title}</h3>
            <p className="text-faded small mb8 mt8">{todo?.content?.description}</p>
            <div className="mb16 mt16 text-center pos-rel">
              <img
                src={todo?.content.images.desktop.detail ?? Images.toDoFallbackImage}
                alt=""
                className="main-image"
              />
              {todo.content.images.platformIcon && (
                <div className="pos-abs l6 t10">
                  <img height="42px" src={todo.content.images.platformIcon} />
                </div>
              )}
              {todo.category === 'partners' && (
                <div className="pos-abs l8 t8">
                  <SaveWithProBadge />
                </div>
              )}
            </div>
            <ul className="pl20 pr20">
              {todo?.content.body.map((item: string, index: number) => (
                <li key={index} className="text-faded small">
                  <p className="text-faded small mb8">{item}</p>
                </li>
              ))}
            </ul>
            {todo?.content?.promotionCode && todo?.category === 'partners' && (
              <Button
                className="btn-white w100p ml0"
                onClick={() => {
                  navigator.clipboard.writeText(todo.content.promotionCode);
                  dispatchSnackbar({
                    type: 'OPEN_SNACKBAR',
                    payload: {
                      message: todo?.content?.promotionCode + ' ' + t('COMMON.COPIED-TO-CLIPBOARD'),
                      type: 'success',
                    },
                  });
                }}
              >
                <Icon>content_copy</Icon>
                <span className="pl8">{t('COMMON.COPY-TO-CLIPBOARD')}</span>
              </Button>
            )}
            {todo?.content?.button && (
              <Button className="btn-white w100p ml0" onClick={() => openTodo()}>
                {todo?.content?.button?.text}
              </Button>
            )}

            {todo?.category === 'partners' && !isSubscribed && (
              <Button
                className="btn-black-2 w100p ml0"
                onClick={() => {
                  navigate('/home?subscription=true');
                }}
              >
                <span className="pl8">{'Unlock Pro exclusive discount'}</span>
              </Button>
            )}

            <Button className="btn-black-2 w100p ml0" onClick={handleModalClose}>
              {t('COMMON.DISMISS')}
            </Button>
          </div>
        </>
      </Modal>
    </>
  );
};

export default ToDoModal;
